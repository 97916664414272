import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Lottie from 'lottie-react'
import lottieLogin from './lottieLogin.json'
import { Column } from 'src/components/Layout/Styled'
import Template from 'src/components/Template'
import {
  checkManagerInPath,
  checkStartsWithDms,
  isMediaWidthMoreThan,
} from 'src/utils/lib'
import { io } from 'socket.io-client'
import axios from 'axios'
import {
  StyledLoadingProgress,
  StyledProgressBox,
  StyledProgressLoad,
  StyledWaitingBox,
} from './Styled'
import { handleQueueing } from './events'
import _ from 'lodash'
import { openDialog } from '../../../redux/slices/alertDialog'
import Error401 from '../../../components/AlertDialog/Error401'
import { useDispatch } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'

const { __env__: env } = window

const socketUrl = env.REACT_APP_SOCKET_URL

const socket = io.connect(`${socketUrl}`, {
  autoConnect: false,
  transports: ['websocket', 'polling'],
  auth: {
    Authorization: localStorage.getItem('token'),
  },
})

const WaitingForLogin = () => {
  const dispatch = useDispatch()
  const [totalQueue, setTotalQueueQueue] = useState(0)
  const [countDownQueue, setCountDownQueue] = useState(0)
  const [loadingPercent, setLoadingPercent] = useState(0)
  const [socketId, setSocketId] = useState('')
  const [statusError, setStatusError] = useState(0)
  const [previousPath] = useLocalStorage('previousPath')
  const queueThreshold = Number(window.__env__.REACT_APP_QUEUE_THRESHOLD)

  if (_.isEmpty(localStorage.getItem('token'))) {
    window.location.href = '/login'
  }

  socket.connect()

  function handleSocketConnected() {
    if (socket.connected) {
      setSocketId(socket.id)
      localStorage.removeItem('user')
      socket.on(socket.id, (queue) => {
        const code = _.get(queue, 'statusCode', 0)
        setStatusError(code)
        if (code !== 0) {
          dispatch(
            openDialog({
              type: 'custom',
              content: <Error401 />,
              isCloseDialog: false,
            })
          )
        } else {
          localStorage.setItem('user', JSON.stringify(queue))
          const userType = _.get(queue, 'userType', null)
          if (userType === 'AGENT' && checkStartsWithDms(previousPath)) {
            const agentRole = _.get(queue, 'agentRole', null)
            if (agentRole === 'Manager') {
              if (
                previousPath === '/dms/' ||
                !checkManagerInPath(previousPath)
              ) {
                window.location = '/dms/manager/dashboard'
              }
            } else if (agentRole === 'Agent') {
              if (
                previousPath === '/dms/' ||
                checkManagerInPath(previousPath)
              ) {
                window.location = '/dms/agent/home'
              }
            } else {
              window.location = !_.isEmpty(previousPath) ? previousPath : '/'
            }
          } else {
            window.location = !_.isEmpty(previousPath) ? previousPath : '/'
          }
        }
      })

      socket.on('ams_queue', () => {
        setCountDownQueue((o) => o - 1)
      })
    }
  }

  useEffect(() => {
    function onConnect() {
      handleSocketConnected()
    }

    function onDisconnect() {
      socket.disconnect()
    }

    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)

    return () => {
      socket.off('connect', onConnect)
      socket.off('disconnect', onDisconnect)
    }
  }, [])

  useEffect(() => {
    if (totalQueue >= queueThreshold) {
      setLoadingPercent(100 - (countDownQueue / totalQueue) * 100)
    }
  }, [countDownQueue])

  useEffect(() => {
    if (socketId) {
      const token = localStorage.getItem('token')
      axios
        .post(`/queue`, { id: socketId, token })
        .then((res) => {
          setTotalQueueQueue(res.data)
          setCountDownQueue(res.data)
        })
        .catch((err) => {
          console.log(err.message)
        })
    }
  }, [socketId])

  const isNotSm = isMediaWidthMoreThan('sm')

  useEffect(() => {
    if (loadingPercent > 100) return
    handleQueueing(loadingPercent)
  }, [loadingPercent])

  if (statusError !== 0) {
    return <div style={{ width: '100vw', height: '100vh' }} />
  }

  if (totalQueue < queueThreshold) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundColor: '#FFFFFF',
          zIndex: '99999',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={30} />
        <Typography sx={{ mt: 2 }}>กรุณารอสักครู่</Typography>
      </Box>
    )
  }

  return (
    <Template
      sx={{
        mt: 9,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <StyledWaitingBox>
        <Column sx={{ width: 'auto', justifyContent: 'center', gap: 2 }}>
          <Typography variant="h4" color="primary" textAlign="center">
            กรุณารอสักครู่{isNotSm ? ' ' : <br />}ทางเรากำลังนำคุณเข้าสู่ระบบ
          </Typography>
          <Typography variant="body1b" color="primary" textAlign="center">
            ขออภัยในความไม่สะดวก เนื่องจากขณะนี้{!isNotSm && <br />}
            มีผู้เข้าใช้งานจำนวนมาก
          </Typography>
        </Column>
        <Lottie
          style={{ width: 290, height: 290 }}
          animationData={lottieLogin}
        />
        <StyledLoadingProgress>
          {Array(20)
            .fill()
            .map((e, index) => (
              <StyledProgressBox key={index}>
                <StyledProgressLoad key={index} className="progress" />
              </StyledProgressBox>
            ))}
        </StyledLoadingProgress>
      </StyledWaitingBox>
    </Template>
  )
}

export default WaitingForLogin
