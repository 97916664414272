import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import _ from 'lodash'
import Resizer from 'react-image-file-resizer'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { isNumeric } from 'src/modules/ManageProspect/Form/events'
import { MONTH_TH_SHORT_LIST } from 'src/constants/calendar'

export const convertFormatDateTime = ({ value, type }) => {
  dayjs.extend(buddhistEra)
  dayjs.extend(utc)
  let result = '-'
  if (type === 'date') {
    result = dayjs(new Date(value))
      .utc()
      .format(window.__env__.REACT_APP_DATE_SHOW)
  }

  if (type === 'dateYYYY') {
    result = dayjs(new Date(value)).utc().format('DD/MM/YYYY')
  }

  if (type === 'dateDb') {
    result = dayjs(new Date(value)).format(window.__env__.REACT_APP_DATE_DB)
  }

  if (type === 'dateTime') {
    result =
      dayjs(new Date(value)).utc().format(window.__env__.REACT_APP_DATE_SHOW) +
      ' ' +
      dayjs(new Date(value)).utc().format(window.__env__.REACT_APP_DATE_TIME)
  }

  if (type === 'time') {
    result = dayjs(new Date(value))
      .utc()
      .format(window.__env__.REACT_APP_DATE_TIME)
  }

  if (type === 'timeWithSec') {
    result = dayjs(new Date(value)).utc().format('HH:mm:ss')
  }

  if (type === 'dayMonth') {
    result = dayjs(new Date(value))
      .utc()
      .format(window.__env__.REACT_APP_DATE_DAY_MONTH)
  }

  return result
}

export const isMediaWidthMoreThan = (breakpoint) => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up(breakpoint))
}

export const isMediaWidth = (breakpoint) => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.only(breakpoint))
}

export const getQueryParams = (field) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get(field)
}

export const convertFormatTime = (data, isShort) => {
  const hours = _.get(data, 'hours', 0)
  const minutes = _.get(data, 'minutes', 0)
  const hourText = isShort ? 'ชม.' : 'ชั่วโมง'

  if (hours > 0 && minutes > 0) return `${hours} ${hourText} ${minutes} นาที`
  if (hours === 0) return `${minutes} นาที`
  if (minutes === 0) return `${hours} ${hourText}`
}

export const convertDateRange = (dateRange) => {
  const startDay = _.get(dateRange, 'startDateDay', '')
  const startMonth = _.get(dateRange, 'startDateMonth', '')
  const startYear = _.get(dateRange, 'startDateYear', '')
  const finishDate = _.get(dateRange, 'finishDate', '')
  const finishDay = _.get(dateRange, 'finishDateDay', '')
  const finishMonth = _.get(dateRange, 'finishDateMonth', '')
  const finishYear = _.get(dateRange, 'finishDateYear', '')
  const startDateMonthYear = `${startDay} ${startMonth} ${startYear}`
  if (
    startDay === finishDay &&
    startMonth === finishMonth &&
    startYear === finishYear
  ) {
    return startDateMonthYear
  }
  if (finishDate === 'unlimit') {
    return `ไม่จำกัดเวลา`
  }
  return `${startDay} ${startMonth} ${startYear} - ${finishDay} ${finishMonth} ${finishYear}`
}

export const convertDate = (date) => {
  const newdate = date.split('-')
  return `${newdate[2]}/${newdate[1]}/${newdate[0]} `
}

export const handleDownloadSuccess = (res, fileName) => {
  const url = window.URL.createObjectURL(new Blob([res.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

export const downloadFileTypePost = createAsyncThunk(
  'downloadFileTypePost',
  async ({ url, body, fileName }, { rejectWithValue }) => {
    try {
      await axios
        .post(url, body, { responseType: 'blob' })
        .then((res) => handleDownloadSuccess(res, fileName))
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const downloadFileTypeGet = createAsyncThunk(
  'downloadFileTypeGet',
  async ({ url, fileName }, { rejectWithValue }) => {
    try {
      await axios
        .get(url, { responseType: 'blob' })
        .then((res) => handleDownloadSuccess(res, fileName))
    } catch (err) {
      return rejectWithValue([], err)
    }
  }
)

export const checkIdCard = (id) => {
  if (!isNumeric(id)) return false
  if (id.substring(0, 1) === 0) return false
  if (id.length !== 13) return false
  let sum = 0
  for (let i = 0; i < 12; i++) sum += parseFloat(id.charAt(i)) * (13 - i)
  return (11 - (sum % 11)) % 10 === parseFloat(id.charAt(12))
}

export const startTest = (
  uuid,
  trainingPlanUuid,
  type,
  courseTestAndEvaluateDataUuid
) => {
  const eTestLink = `${window.__env__.REACT_APP_APP_URL}/e-testing/${courseTestAndEvaluateDataUuid}?class=${trainingPlanUuid}`
  const evaLink = `${window.__env__.REACT_APP_APP_URL}/e-evaluation/${uuid}?class=${trainingPlanUuid}&eva=${courseTestAndEvaluateDataUuid}
`
  if (type === 'E_TESTING') window.location.href = eTestLink
  else window.location.href = evaLink
}

export const replaceNewLine = (text) => {
  if (_.isEmpty(text)) return null
  return text.replace(/\r\n|\r|\n/g, '\\n')
}

export const parseJwt = (token) => {
  try {
    return JSON.parse(decodeURIComponent(atob(token.split('.')[1])))
  } catch (e) {
    return null
  }
}

export const replaceProtocol = (url) => {
  if (_.isEmpty(url)) return url
  if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) return url
  return `https://${url}`
}

export const isScrolledIntoElement = (elem, fullyInView) => {
  const element = document.documentElement
  const pageTop = element.scrollTop
  const pageBottom = pageTop + window.innerHeight
  const elementTop = elem.offsetTop
  const elementBottom = elementTop + elem.offsetHeight

  if (fullyInView) {
    return pageTop < elementTop && pageBottom > elementBottom
  } else {
    return elementTop <= pageBottom - 10 && elementBottom >= pageTop
  }
}

let saveTimer
export const saveDebounce = (callback) => {
  let debounceTime = 500

  if (saveTimer) {
    clearTimeout(saveTimer)
  }

  saveTimer = setTimeout(() => {
    callback()
  }, debounceTime)
}

export const isValidateDate = (date) => {
  if (date === '0') return false
  if (!date) return false
  return dayjs(date).isValid()
}

export const getGenderFromPrefix = (prefix, genderOptions) => {
  if (prefix) {
    const gender =
      prefix == 'นาย'
        ? _.find(genderOptions, (ele) => ele.label == 'ช')
        : _.find(genderOptions, (ele) => ele.label == 'ญ')
    return gender.value
  } else {
    return ''
  }
}

export const convertNumberWithComma = (value) => {
  return parseInt(value || 0).toLocaleString()
}

export const convertDateToThaiString = (value) => {
  const date = new Date(value)
  const month = MONTH_TH_SHORT_LIST[date.getMonth()]
  const buddistYear = date.getFullYear() + 543
  return `${date.getDate()} ${month} ${buddistYear}`
}

export const convertDurationToTimeRange = (duration, startDateTime) => {
  const dateTime = dayjs(startDateTime).utc()
  const endTime = dateTime.add(duration, 'second')
  return {
    startTime: convertFormatDateTime({
      value: new Date(startDateTime),
      type: 'timeWithSec',
    }),
    endTime: convertFormatDateTime({
      value: endTime,
      type: 'timeWithSec',
    }),
  }
}

export const convertDateShortTh = (date) => {
  dayjs.extend(buddhistEra)
  dayjs.locale('th')
  return dayjs(date).format('DD MMM BBBB')
}

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',')
  const matched = arr[0].match(/:(.*?);/) // NOSONAR
  const mime = _.get(matched, 1, '')
  const bstr = window.atob(arr[arr.length - 1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export const handleResizeFile = (
  file,
  minWidth,
  minHeight,
  maxWidth = 1200,
  maxHeight = 1200
) => {
  const fileName = _.get(file, 'name', null)
  const fileType = _.lowerCase(fileName.split('.').pop())
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      fileType,
      100,
      0,
      (uri) => resolve(uri),
      'file',
      minWidth,
      minHeight
    )
  })
}

export function validateNameEN(values) {
  if (!/^[a-zA-Z]+$/.test(values)) {
    return ''
  }
  return values
}

export const checkManagerInPath = (path) => {
  const regex = /^\/dms\/manager\//
  return regex.test(path)
}

export const checkStartsWithDms = (path) => {
  const regex = /^\/dms/
  return regex.test(path)
}
